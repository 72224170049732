.App {
  text-align: center;
}

.App {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

.Header, .Footer {
  margin: 0, 0, 0, 0;
  width: 80vw;
  max-width: 1800px;
  align-items: center;
}

.a {
  color: black
}

.header4 {
  font-size: large
}

.header5 {
  font-size: medium;
  font-weight: bold;
}